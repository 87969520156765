import { createSlice } from "@reduxjs/toolkit";

const vendor_data =
  JSON.parse(localStorage.getItem("flow_vendor_data")) || null;

const initialState = {
  personal_info: {
    vendor_name: vendor_data?.personal_info?.vendor_name,
    mobile: vendor_data?.personal_info?.mobile,
    email_id: vendor_data?.personal_info?.email_id,
  },
  store_info: {
    store_id: vendor_data?.store_info?.store_id,
    store_name: vendor_data?.store_info?.store_name,
    store_image: vendor_data?.store_info?.store_image,
    isActive: vendor_data?.store_info?.isActive,
  },
  bank_details: {
    benificiary_name: vendor_data?.bank_details?.benificiary_name,
    bank_name: vendor_data?.bank_details?.bank_name,
    branch: vendor_data?.bank_details?.branch,
    account_number: vendor_data?.bank_details?.account_number,
    iban_number: vendor_data?.bank_details?.iban_number,
    swift_code: vendor_data?.bank_details?.swift_code,
  },
  vendor_documents: vendor_data?.vendor_documents || [],
};

const vendorProfileSlice = createSlice({
  name: "vendor_profile",
  initialState,
  reducers: {
    set_personal_info: (state, action) => {
      state.personal_info.vendor_name = action.payload.name;
      state.personal_info.mobile = action.payload.phone_number;
      // console.log("from slice: ", action.payload.phone_number);
      state.personal_info.email_id = action.payload.email_id;
      localStorage.setItem("flow_vendor_data", JSON.stringify(state));
    },
    set_store_info: (state, action) => {
      state.store_info.store_name = action.payload.store_name;
      state.store_info.store_id = action.payload.store_id;
      state.store_info.store_image = action.payload.store_image;
      state.store_info.isActive = action.payload.isActive;
      localStorage.setItem("flow_vendor_data", JSON.stringify(state));
    },
    set_bank_details: (state, action) => {
      state.bank_details.benificiary_name = action.payload.benificiary_name;
      state.bank_details.bank_name = action.payload.bank_name;
      state.bank_details.branch = action.payload.branch;
      state.bank_details.account_number = action.payload.account_number;
      state.bank_details.iban_number = action.payload.iban_number;
      state.bank_details.swift_code = action.payload.swift_code;
      localStorage.setItem("flow_vendor_data", JSON.stringify(state));
    },
    set_vendor_docs: (state, action) => {
      state.vendor_documents = action.payload;
      localStorage.setItem("flow_vendor_data", JSON.stringify(state));
    },
  },
});

export const {
  set_personal_info,
  set_store_info,
  set_bank_details,
  set_vendor_docs,
} = vendorProfileSlice.actions;
export default vendorProfileSlice.reducer;
